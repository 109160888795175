<script setup lang="ts">
import { OnboardingReward } from "user/models/initial-rewards";
import { isStarsLootbox, Reward } from "user/models/items";
import { ButtonSize } from "user/components/FlatButton.vue";
import { Currency } from "user/models/currency";

defineProps<{
  prize: Reward | OnboardingReward;
  hideText: boolean;
  lbAnimationActive: boolean;
  isMarket?: boolean;
  sellingPrice?: Currency;
}>();
const isDoneFirstPartOfAnimation = ref(false);

const emit = defineEmits<{
  openLootbox: [];
  startPrizeAnimation: [];
}>();
</script>

<template>
  <div class="flex flex-col justify-center items-center gap-4 min-h-[190rem]">
    <ReceiveModalHeader
      :class="{
        'text-black': isStarsLootbox(prize),
        'text-white': !isStarsLootbox(prize),
        hidden: hideText || !isDoneFirstPartOfAnimation,
      }"
    >
      HOORAY!
    </ReceiveModalHeader>
    <div
      class="text-center text-[20rem]/[28rem] font-medium text-black"
      :class="{
        hidden: hideText || !isDoneFirstPartOfAnimation,
      }"
    >
      <div>
        <div>
          You’ve won a
          <span class="font-bold"> {{ prize.name }} </span>
        </div>
        <div>Open the lootbox to see what's inside!</div>
      </div>
    </div>
  </div>

  <div class="fixed flex justify-center items-center top-0 h-full w-full">
    <LootboxAnimation
      :activate="lbAnimationActive"
      @done="emit('startPrizeAnimation')"
      :prize="prize"
      @is-stopped="isDoneFirstPartOfAnimation = true"
    ></LootboxAnimation>
  </div>

  <div
    class="flex flex-col gap-2 min-h-[190rem] justify-end"
    :class="{
      hidden: hideText || !isDoneFirstPartOfAnimation,
    }"
  >
    <ButtonWithSound
      @click="emit('openLootbox')"
      :size="ButtonSize.md"
      class="z-[60] shadow-home"
    >
      Next
    </ButtonWithSound>
  </div>
</template>
