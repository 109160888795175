<script setup lang="ts">
import {
  Dialog,
  TransitionChild,
  DialogPanel,
  TransitionRoot,
} from "@headlessui/vue";

const isOpen = defineModel<boolean>("open", { required: true });
defineProps<{ turnOffMouseOutsideClose?: boolean }>();

function closeModal() {
  isOpen.value = false;
  setTimeout(() => {
    emit("close");
  }, 300);
}

const emit = defineEmits<{
  confirm: [];
  close: [];
}>();
</script>

<template>
  <TransitionRoot :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
        class="opacity-0"
      >
        <div class="fixed inset-0 bg-black/60" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            class="opacity-0 scale-95"
          >
            <template v-if="!turnOffMouseOutsideClose">
              <DialogPanel
                @click="emit('confirm')"
                v-bind="$attrs"
                class="flex justify-center h-[500rem] w-[325rem] rounded-ml bg-white text-black backdrop-blur-[7.5px] transform overflow-hidden border-0 text-left align-middle shadow-xl transition-all"
              >
                <slot />
              </DialogPanel>
            </template>
            <template v-else>
              <div
                @click="emit('confirm')"
                v-bind="$attrs"
                class="flex justify-center h-[500rem] w-[325rem] rounded-ml bg-white text-black backdrop-blur-[7.5px] transform overflow-hidden border-0 text-left align-middle shadow-xl transition-all"
              >
                <slot />
              </div>
            </template>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
