<script lang="ts">
export enum ButtonSoundEnum {
  default = "default",
  purchase = "purchase",
}
</script>

<script setup lang="ts">
import clickButtonSound from "user/assets/audio/buttons/default-click";
import purchaseButtonSound from "user/assets/audio/buttons/purchase-click";
import { IFlatButtonProps } from "./FlatButton.vue";

const props = withDefaults(
  defineProps<IFlatButtonProps & { sound?: ButtonSoundEnum }>(),
  {
    sound: ButtonSoundEnum.default,
  },
);

const emits = defineEmits<{
  click: [];
}>();

defineOptions({
  inheritAttrs: false,
});

function handleClick() {
  switch (props.sound) {
    case ButtonSoundEnum.default:
      clickButtonSound.play();
      break;
    case ButtonSoundEnum.purchase:
      purchaseButtonSound.play();
      break;
    default:
      clickButtonSound.play();
      break;
  }

  emits("click");
}

const childRef = ref();

// Compiler macros, such as defineExpose, don't need to be imported
defineExpose({
  childRef
});
</script>

<template>
  <FlatButton
    ref="childRef"
    @click="handleClick"
    :borderless
    :icon
    :trailing-icon
    :disabled
    :size
    :type
    :font-bold
    :kind
    v-bind="$attrs"
  >
    <slot />
  </FlatButton>
</template>
