<script setup lang="ts">
import { ButtonSize } from "user/components/FlatButton.vue";
import signup from "user/assets/sign-up.png";

defineEmits<{
  confirm: [];
}>();

const open = defineModel<boolean>("open", { required: true });
</script>

<template>
  <Modal v-model:open="open">
    <div
      class="flex flex-col items-center px-[47rem] pt-[50rem] text-center z-50"
    >
      <div class="pb-4 text-[30rem] font-semibold">OH WAIT!</div>
      <div class="text-[20rem] font-medium">
        Create an account or Sign in to access Roblox rewards
      </div>
      <img :src="signup" class="my-[35rem] h-[80rem] w-[200rem]" />
      <ButtonWithSound :size="ButtonSize.md" @click="$emit('confirm')">
        Let's Go
      </ButtonWithSound>
    </div>
  </Modal>
</template>
