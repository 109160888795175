import { Rarity, RewardClassName } from "user/models/items";

export function displayCategory(class_name?: RewardClassName) {
  switch (true) {
    case class_name === RewardClassName.Merch:
      return "Merch";
    case class_name === RewardClassName.Gems:
      return "GEMs";
    case class_name === RewardClassName.STW:
      return "Spins";
    case class_name === RewardClassName.Stars:
      return "STARs";
    case class_name === RewardClassName.UGC:
      return "UGC";
    case class_name === RewardClassName.Badge:
      return "Badge";
    case class_name === RewardClassName.Lootbox:
    case class_name === RewardClassName.Others:
    case class_name === RewardClassName.Badge:
    case class_name === RewardClassName.Robux:
    case class_name === RewardClassName.RobuxCard:
      return "Default";
    default:
      return "Default";
  }
}

export function displayCreatorName(
  creator?: string,
  class_name?: RewardClassName,
) {
  if (class_name === RewardClassName.Gems) {
    return "GEMs";
  }

  if (class_name === RewardClassName.Stars) {
    return "STARs";
  }

  if (creator) {
    return creator;
  }

  return "";
}

export function getRewardNameBackground(rarity: Rarity) {
  switch (true) {
    case rarity === Rarity.merch:
      return "bg-rewardCardName-merch";
    case rarity === Rarity.gems:
      return "bg-rewardCardName-gems";
    case rarity === Rarity.spins:
      return "bg-rewardCardName-spin";
    case rarity === Rarity.stars:
      return "bg-rewardCardName-stars";
    case rarity === Rarity.Mystical:
      return "bg-rewardCardName-mystical";
    case rarity === Rarity.epic:
      return "bg-rewardCardName-epic";
    case rarity === Rarity.legendary:
      return "bg-rewardCardName-legendary";
    case rarity === Rarity.rare:
      return "bg-rewardCardName-rare";
    case rarity === Rarity.special:
      return "bg-rewardCardName-special";
    case rarity === Rarity.merch:
      return "bg-rewardCardName-merch";
    case rarity === Rarity.badge:
      return "bg-rewardCardName-badge";
    case rarity === Rarity.default:
    default:
      return "bg-rewardCardName-default";
  }
}

export function displayRewardName(
  name: string,
  mission_reward_qty: number,
  class_name?: RewardClassName,
) {
  if (
    class_name === RewardClassName.Gems ||
    class_name === RewardClassName.Stars
  ) {
    return new Intl.NumberFormat("en", { notation: "standard" }).format(
      mission_reward_qty,
    );
  }
  if (class_name === RewardClassName.STW) {
    return mission_reward_qty + " x Spins";
  }

  return name;
}

export function getBackgroundCategoryImage(rarity: Rarity) {
  if (rarity === Rarity.gems) {
    return "bg-rewardCardRarity-gems";
  }
  if (rarity === Rarity.spins) {
    return "bg-rewardCardRarity-spin";
  }
  if (rarity === Rarity.stars) {
    return "bg-rewardCardRarity-stars";
  }
  if (rarity === Rarity.Mystical) {
    return "bg-rewardCardRarity-mystical";
  }
  if (rarity === Rarity.epic) {
    return "bg-rewardCardRarity-epic";
  }
  if (rarity === Rarity.legendary) {
    return "bg-rewardCardRarity-legendary";
  }
  if (rarity === Rarity.rare) {
    return "bg-rewardCardRarity-rare";
  }
  if (rarity === Rarity.special) {
    return "bg-rewardCardRarity-special";
  }
  if (rarity === Rarity.default) {
    return "bg-rewardCardRarity-default";
  }
  if (rarity === Rarity.merch) {
    return "bg-rewardCardRarity-merch";
  }
  if (rarity === Rarity.badge) {
    return "bg-rewardCardRarity-badge";
  }

  return "bg-rewardCardRarity-default";
}
