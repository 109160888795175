<script setup lang="ts">
import { OnboardingReward } from "user/models/initial-rewards";
import { Reward } from "user/models/items";
import { ButtonSize } from "user/components/FlatButton.vue";

defineProps<{
  prize: Reward | OnboardingReward;
  hideTextJackpot: boolean;
  jackpotAnimationActive: boolean;
}>();

const isDoneFirstPartOfAnimation = ref(false);

const emit = defineEmits<{
  endJackpotAnimation: [];
  getJackpot: [];
}>();
</script>

<template>
  <div class="flex flex-col justify-center items-center min-h-[190rem] gap-4">
    <ReceiveModalHeader
      :class="{
        hidden: hideTextJackpot || !isDoneFirstPartOfAnimation,
      }"
      class="text-white"
    >
      HOORAY!
    </ReceiveModalHeader>
    <div
      v-if="!hideTextJackpot && isDoneFirstPartOfAnimation"
      class="text-center text-[20rem]/[28rem] font-medium"
    >
      <span>
        You've won a <span class="font-bold">{{ prize.name }}</span
        >!
      </span>
    </div>
  </div>
  <div class="fixed flex justify-center items-center top-0 h-full w-full">
    <LootboxAnimation
      :activate="jackpotAnimationActive"
      @done="emit('endJackpotAnimation')"
      :prize="prize"
      @is-stopped="isDoneFirstPartOfAnimation = true"
    ></LootboxAnimation>
  </div>

  <div
    class="flex flex-col gap-2 min-h-[190rem] justify-end"
    :class="{
      hidden: hideTextJackpot || !isDoneFirstPartOfAnimation,
    }"
  >
    <ButtonWithSound
      @click="emit('getJackpot')"
      :size="ButtonSize.md"
      class="z-[60] shadow-home"
    >
      Claim
    </ButtonWithSound>
  </div>
</template>
