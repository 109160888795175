<script setup lang="ts">
import { useVModel } from "@vueuse/core";

const props = defineProps<{
  modelValue?: string;
  isInProgress?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string];
}>();

const modelValue = useVModel(props, "modelValue", emit);
</script>

<template>
  <div class="flex flex-col gap-4 w-full">
    <FunInput
      id="enterCode"
      class="text-[15rem]/[22rem] shadow-home rounded-[6rem] text-center"
      placeholder="Enter code"
      v-model="modelValue"
      :error="isError"
      :class="{
        'text-jadeMountain border-jadeMountain border-[2rem]': isSuccess,
        'cursor-wait': isInProgress,
      }"
    />
    <span v-if="isError" class="text-red text-[15rem]/[22rem] text-center">
      Invalid code. Please try again!
    </span>

    <span
      v-if="isSuccess"
      class="text-jadeMountain text-[15rem]/[22rem] text-center"
    >
      Success!
    </span>
  </div>
</template>
