import { Currency } from "user/models/currency";
import { Providers } from "./mission";

export const enum UserRole {
  Admin = "administrator",
  Operator = "operator",
  Manager = "manager",
  Anonymous = "anonymous",
  Customer = "customer",
}

export enum UserKind {
  Authorized = "authorized",
  Anonymous = "anonymous",
}

export interface User {
  email?: string;
  name?: string;
  referral_code?: string;
  kind: UserKind;
  free_spin_at: string;
  currencies: Currency;
  ugcs_collected: number;
  missions_completed: number;
  authorization_providers: Providers[];
  user_id: number;
}
