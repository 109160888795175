<script setup lang="ts">
import { ButtonSize } from "user/components/FlatButton.vue";
import { TWITTER_POST_TEXT } from "user/components/shareReward/constants";

const emit = defineEmits<{
  copyImage: [];
  share: [];
}>();

function shareOnTwitter() {
  // const params = {
  //   text: TWITTER_POST_TEXT,
  // };

  let shareURL = "https://x.com/intent/post?text=No+way%21+Jumped+onto+%40PLAYSIDEgg+and+won+a+FREE+ROBLOX+UGC+limited%21+%F0%9F%94%A5%0D%0A%0D%0ATry+it+now%3A+app.playside.gg+%23PLAYGROUND"

  window.open(shareURL, "_blank");
  emit("share");
}
</script>

<template>
  <div class="relative grid grid-cols-2 gap-3">
    <ButtonWithSound
      id="copy-image-button"
      @click="emit('copyImage')"
      :size="ButtonSize.full"
      class="z-[60] shadow-home hover:scale-105 transition-all"
      kind="hollow"
    >
      Copy Image
    </ButtonWithSound>
    <ButtonWithSound
      @click="shareOnTwitter"
      :size="ButtonSize.full"
      class="z-[60] shadow-home hover:scale-105 transition-all"
      kind="hollow"
    >
      Post on X
    </ButtonWithSound>
    <div
      class="col-span-2 flex justify-end static md:absolute md:right-[-100px] md:bottom-[12px]"
    >
      <PrizeForShare align-star-right-on-mobile />
    </div>
  </div>
</template>
