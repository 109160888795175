<script setup lang="ts">
import { OnboardingReward } from "user/models/initial-rewards";
import { Reward } from "user/models/items";
import { ButtonSize } from "user/components/FlatButton.vue";

defineProps<{
  prize: Reward | OnboardingReward;
  hideTextMB: boolean;
  mbAnimationActive: boolean;
  isAnimated: boolean;
}>();

const emit = defineEmits<{
  openMysteryBox: [];
  endMysteryBoxAnimation: [];
}>();
</script>

<template>
  <div class="flex flex-col justify-center items-center min-h-[190rem] gap-4">
    <ReceiveModalHeader
      :class="{
        hidden: hideTextMB,
      }"
      class="text-white"
    >
      HOORAY!
    </ReceiveModalHeader>
    <div
      v-if="!hideTextMB"
      class="text-center text-[20rem]/[28rem] font-medium text-black"
    >
      <div>
        <div>You’ve won a <span class="font-bold">Mystery Box</span> from</div>
        <div>playing Spin The Wheel!</div>
      </div>
    </div>
  </div>
  <div class="fixed flex justify-center items-center top-0 h-full w-full">
    <LootboxAnimation
      :activate="mbAnimationActive"
      @done="emit('endMysteryBoxAnimation')"
      :prize="prize"
    ></LootboxAnimation>
  </div>

  <div
    class="flex flex-col gap-2 min-h-[190rem] justify-end"
    :class="{
      hidden: hideTextMB,
    }"
  >
    <ButtonWithSound
      @click="emit('openMysteryBox')"
      :size="ButtonSize.md"
      class="z-[60] shadow-home"
      :disabled="isAnimated"
    >
      Next
    </ButtonWithSound>
  </div>
</template>
