import container from "shared/inversify.config";

import { defineStore } from "pinia";
import { ref, shallowReadonly } from "vue";
import { Notification } from "user/models/notifications";
import { NotificationApi } from "user/api/notifications";
import { useSessionStore } from "./session";
import { useLocalStorage } from "@vueuse/core";

const onboardNotificationType = "New User Reward";

export const useNotificationStore = defineStore("notification", () => {
  const notificationQueue = ref<Notification[]>([]);
  const indexInQueue = ref<number>(0);
  const session = useSessionStore();

  const onboardReward = useLocalStorage<undefined | string>(
    "onboard_reward",
    undefined,
  );
  const onboardRewardUserId = useLocalStorage<undefined | number>(
    "onboard_reward_user",
    undefined,
  );

  function $reset() {
    notificationQueue.value = [];
    indexInQueue.value = 0;
  }

  function resetOnboard() {
    onboardReward.value = "hide";
  }

  async function fetchNotifications() {
    if (notificationQueue.value.length == 0 && indexInQueue.value == 0) {
      const notificiations = await container
        .get(NotificationApi)
        .getNotifications();

      // const onboard = (notificiations as Notification[]).find(
      //   (notif) => notif.new === true && notif.type === onboardNotificationType,
      // );

      if (!session.isAnonymous) {
        onboardReward.value = "hide";
      }

      if (session.currentUser?.user_id && session.isAnonymous) {
        if (
          Number(onboardRewardUserId.value) !==
          Number(session.currentUser.user_id)
        ) {
          onboardRewardUserId.value = session.currentUser?.user_id;
          onboardReward.value = undefined;
        }
        if (onboardReward.value === undefined) {
          onboardReward.value = "show";
        }
      }

      // FILTER OUT NEW
      notificationQueue.value = (notificiations as Notification[])
        .filter((notif) => notif.reward != null && notif.new)
        .map((notif) => {
          if (notif.reward) {
            notif.reward.notification_amount = notif.amount;
          }
          return notif;
        });

      if (notificationQueue.value.length > 0) {
        session.tryFetchCurrentUser();
      }
    }
  }
  /// returns wether or not you should continue to the next category
  function stepNotificationAndProgress() {
    indexInQueue.value = indexInQueue.value + 1;
    if (indexInQueue.value == notificationQueue.value.length) {
      $reset();
      return true;
    }
    return false;
  }

  function getRewardById(index: number) {
    if (index <= notificationQueue.value.length) {
      return notificationQueue.value[index];
    }
  }

  return {
    $reset,
    notificationQueue: shallowRef(notificationQueue),
    indexInQueue: shallowReadonly(indexInQueue),
    onboardReward: shallowReadonly(onboardReward),
    onboardRewardUserId: shallowReadonly(onboardRewardUserId),
    fetchNotifications,
    stepNotificationAndProgress,
    getRewardById,
    resetOnboard,
  };
});
