<script setup lang="ts">
import "reflect-metadata";
import "@aarsteinmedia/dotlottie-player";
import "./poppins.js";
import { RouterView } from "vue-router";
import { useSettingStore } from "./stores/settings.js";
import { intervalToDuration } from "date-fns";
import { useSessionStore } from "./stores/session.js";
import { storeToRefs } from "pinia";
import { useStorage } from "@vueuse/core";
import { useNotificationStore } from "./stores/notifications.js";

const settingsStore = useSettingStore();
const sessionStore = useSessionStore();
const { settings } = storeToRefs(settingsStore);
const { currentUser, isAnonymous } = storeToRefs(sessionStore);
const notificationStore = useNotificationStore();

const router = useRouter();

const ts_when_ad_showed = useStorage("ad_ts_showed", ""); // returns Ref<boolean>
const is_visited_stw = useStorage("is_visited_stw", 0);

watch([router.currentRoute, currentUser, is_visited_stw], () => {
  if (!router.currentRoute.value.name) {
    return;
  }
  if (!currentUser.value) {
    return;
  }
  if (
    router.currentRoute.value.name === "login" ||
    router.currentRoute.value.name === "spin-the-wheel"
  ) {
    return;
  }
  if (
    new Date() < new Date(currentUser.value.free_spin_at) &&
    !currentUser.value?.currencies.spin_vouchers
  ) {
    return;
  }

  if (is_visited_stw.value !== 0) {
    const duration = intervalToDuration({
      start: Number(is_visited_stw.value),
      end: Date.now(),
    });
    if (!duration.hours) {
      return;
    }

    if (duration.hours < 24) {
      return;
    }
  }

  router.push({ name: "spin-the-wheel" });
  is_visited_stw.value = Date.now();
});

const showAd = computed({
  get() {
    if (notificationStore.onboardReward !== undefined) {
      return false;
    }
    if (
      !settings.value.ad_button_color ||
      !settings.value.ad_button_link ||
      !settings.value.ad_button_text ||
      !settings.value.ad_poster_image ||
      !settings.value.ad_repeat_interval_hours
    ) {
      return false;
    }
    if (isAnonymous.value || !currentUser.value) {
      return false;
    }
    if (!ts_when_ad_showed.value) {
      return true;
    }

    const duration = intervalToDuration({
      start: Number(ts_when_ad_showed.value),
      end: Date.now(),
    });

    const intervalCheck = settingsStore.settings.ad_repeat_interval_hours
      ? settingsStore.settings.ad_repeat_interval_hours
      : 8;

    if (duration.hours && Math.abs(duration.hours) >= intervalCheck) {
      return true;
    }

    return false;
  },
  set() {
    ts_when_ad_showed.value = Date.now().toString();
  },
});

onMounted(() => {
  settingsStore.load();
});
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <OnboardingHandler />
  </div>
  <AdModal v-model:showAd="showAd" />
  <MissionModal />
</template>

<style>
:root {
  font-size: min(calc(100vw / 390), 1px);
}

* {
  -webkit-tap-highlight-color: transparent;
}
</style>
