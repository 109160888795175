<script setup lang="ts">
// const open = defineModel<boolean>("open", { required: true });

const props = defineProps<{
  open: boolean;
  asset: string;
  lootbox?: boolean;
}>();

const animStart = ref(false);
// delay 75 mils, then activate animation
watch(
  () => props.open === true,
  () => {
    setTimeout(() => {
      animStart.value = true;
      triggerEnd();
    }, 400);
  },
);

onMounted(() => {
  if (props.open) {
    setTimeout(() => {
      animStart.value = true;
      triggerEnd();
    }, 400);
  }
});

const emit = defineEmits<{
  done: [];
}>();

function triggerEnd() {
  setTimeout(() => emit("done"), 1300);
  // emit("done")
}
</script>
<template>
  <img
    :class="{
      'animate-prize': animStart,
      'resting-element': !animStart,
      'mt-[10%] relative': lootbox,
    }"
    class="max-h-full"
    :src="asset"
  />
</template>

<style>
.resting-element {
  opacity: 0;
  transform: scale(0);
}
</style>
