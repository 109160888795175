<script setup lang="ts">
import { useNotificationStore } from "user/stores/notifications";
import { Rarity, Reward, RewardClassName } from "user/models/items";

import stwImage from "user/assets/rewards/stw.png";

const prizeItem = reactive<Reward>({
  class_name: RewardClassName.Stars,
  rarity: Rarity.spins,
  name: "3 spins",
  description: "",
  icon: stwImage,
  box_animation: stwImage,
  notification_amount: 0,
});

const notificationStore = useNotificationStore();

const router = useRouter();

const openPrizes = ref(false);

const openNotification = ref(false);

const active = computed(
  () =>
    router.currentRoute.value.name !== undefined &&
    router.currentRoute.value.name !== "login",
);

function onBoardViewed() {
  notificationStore.resetOnboard();
  reset();
}

function reset() {
  openPrizes.value = false;
  openNotification.value = false;
}

function stepNotifications() {
  const proceed = notificationStore.stepNotificationAndProgress();
  if (proceed) {
    openNotification.value = false;
  }
}

function goToMarket() {
  reset();
  router.push({ name: "market-all", replace: true });
}

function goToSpin() {
  reset();
  router.push({ name: "spin-the-wheel", replace: true });
}

function goToHome() {
  reset();
  router.push({ name: "home", replace: true });
}

function goToBackpack() {
  reset();
  router.push({ name: "backpack-ugc", replace: true });
}

onMounted(async () => {
  setTimeout(async () => {
    // await notificationStore.fetchNotifications();
    if (notificationStore.onboardReward === "show") {
      openPrizes.value = true;
      openNotification.value = false;
    } else if (notificationStore.notificationQueue.length > 0) {
      openNotification.value = true;
      openPrizes.value = false;
    }
  }, 1500);
});

watchEffect(() => {
  if (notificationStore.notificationQueue.length > 0) {
    openNotification.value = true;
    openPrizes.value = false;
  }
});
</script>

<template>
  <ReceiveModalLayout
    v-if="openPrizes && active && prizeItem"
    :open="openPrizes && active"
    :isOnboard="true"
    @update:open="onBoardViewed"
    :prize="prizeItem"
    @continue="onBoardViewed"
    @close="reset"
    @go-to-home="goToHome"
    @go-to-market="goToMarket"
    @spin="goToSpin"
  />
  <div
    v-if="openNotification && active"
    v-for="(item, index) in notificationStore.notificationQueue"
    :key="index"
  >
    <div v-if="item.reward">
      <ReceiveModalLayout
        :open="index === notificationStore.indexInQueue"
        @update:open="stepNotifications"
        :prize="item.reward"
        @continue="stepNotifications"
        :is-multiple="
          notificationStore.notificationQueue.length > 1 &&
          index < notificationStore.notificationQueue.length - 1
        "
        @close="reset"
        @go-to-home="goToHome"
        @go-to-market="goToMarket"
        @go-to-backpack="goToBackpack"
      />
    </div>
  </div>
</template>
