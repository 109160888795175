import "./style.css";
import { createApp } from "vue";
import VueGtagPlugin, { PluginOptions } from "vue-gtag";
import { DotLottiePlayer } from "@aarsteinmedia/dotlottie-player";
import Router from "user/routes";
import router from "user/routes";
import App from "user/app.vue";

declare global {
  interface Window {
    GA_ID?: string;
  }
}

const app = createApp(App);

const VueGtagOptions: PluginOptions = {
  config: {
    id: window.GA_ID ? window.GA_ID : "",
    params: {
      user_id: null,
    },
  },
  enabled: window.GA_ID ? true : false,
  appName: "PLAYSIDE",
};

// Pinia + Axios setup
import { createPinia } from "pinia";

const Pinia = createPinia();
// I18n loader

app
  .use(Router)
  .use(Pinia)
  .use(VueGtagPlugin, VueGtagOptions, router)
  .component("DotLottiePlayer", DotLottiePlayer)
  .provide("axios", app.config.globalProperties.axios)
  .mount("#app");
