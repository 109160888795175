import { injectable } from "inversify";
import type { Mission } from "user/models/mission";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";
import { AxiosResponse } from "axios";
import AnalyticsInstance from "./analytics";

@injectable()
export class MissionApi {
  axios = container.get(Api);

  // TODO: actually fetch mission
  async getMissionById(id: number): Promise<Mission> {
    const { data } = await this.axios.get<{ data: Mission }>(`/missions/${id}`); // ?? just implying this exists, to from json as well
    return data;
  }

  async getMissions(): Promise<MissionResponseStruct> {
    const { data } = await this.axios.get<{ data: MissionResponseStruct }>(
      "/missions",
    );

    return data;
  }

  async triggerLinkout(url: string, claim: boolean): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/linkout`, {
      url,
      claim,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questTriggerLinkout({ url, claim });
    return { success: true, message: res.data };
  }

  async claimStandardMission(mission_id: number): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(
      `/claim_standard_mission`,
      {
        mission_id,
      },
    );

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questCompleted({ mission_id });
    return { success: true, message: res.data };
  }

  async claimDailyLoginMission(mission_id: number): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(
      `/claim_daily_login_mission`,
      {
        mission_id,
      },
    );

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questCompleted({ mission_id });
    return { success: true, message: res.data };
  }

  async startMission(mission_id: number): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/start_mission`, {
      mission_id,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questStarted({ mission_id });
    return { success: true, message: res.data };
  }

  async completeRobloxMission(
    mission_id: number,
    claim: boolean,
  ): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/claim_roblox_mission`, {
      mission_id,
      claim,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questCompleted({ mission_id });
    return { success: true, message: res.data };
  }

  async claimUgc(
    mission_id: number,
    code?: string,
    claim?: boolean,
  ): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/claim_mission_code`, {
      mission_id,
      code,
      claim,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questCompleted({ mission_id, code, claim });
    return { success: true, message: res.data };
  }

  async claimReferralMission(
    mission_id: number,
    claim: boolean,
  ): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(
      `/claim_referral_event_mission`,
      {
        mission_id,
        claim,
      },
    );

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questCompleted({ mission_id, claim });
    return { success: true, message: res.data };
  }

  async claimTwitterMission(
    mission_id: number,
    claim: boolean,
  ): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/claim_twitter_mission`, {
      mission_id,
      claim,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.questCompleted({ mission_id, claim });
    return { success: true, message: res.data };
  }

  async postOnTwitter(reward_id: number) {
    const res = await this.axios.post<AxiosResponse>("/share_to_twitter", {
      reward_id,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    AnalyticsInstance.postOnX({ reward_id });
    return { success: true, message: res.data };
  }
}

interface IMissionResponse {
  success: boolean;
  message: string;
}

interface MissionResponseStruct {
  active: Mission[];
  available: Mission[];
  completed: Mission[];
  claimable: Mission[];
}
