import container from "shared/inversify.config";
import { MissionApi } from "user/api/mission";
import { Mission, MissionClassName } from "user/models/mission";
import { useMissionStore } from "user/stores/mission";
import { useNotificationStore } from "user/stores/notifications";
import AnalyticsInstance from "user/api/analytics";

export const useHandleMission = () => {
  const missionStore = useMissionStore();

  async function startMission(mission: Mission) {
    try {
      const res = await container.get(MissionApi).startMission(mission.id);

      return res;
    } catch (error) {
      throw error;
    }
  }

  async function completeMission(mission: Mission, rewardIds: number[]) {
    const isClaimable = mission.isClaimable ? true : false;

    if (mission.class_name === MissionClassName.RobloxUpdate) {
      try {
        const res = await container
          .get(MissionApi)
          .completeRobloxMission(mission.id, isClaimable);

        if (res.success && isClaimable) {
          AnalyticsInstance.questRewardClaimed({ reward_ids: rewardIds });
          useNotificationStore().fetchNotifications();
        }

        if (res.success && !isClaimable) {
          missionStore.setClaimable(mission.id);
        }

        return res;
      } catch (error) {
        throw error;
      }
    }

    if (mission.class_name === MissionClassName.ReferralEvent) {
      try {
        const res = await container
          .get(MissionApi)
          .claimReferralMission(mission.id, isClaimable);

        if (res.success && isClaimable) {
          AnalyticsInstance.questRewardClaimed({ reward_ids: rewardIds });
          useNotificationStore().fetchNotifications();
        }

        if (res.success && !isClaimable) {
          missionStore.setClaimable(mission.id);
        }

        return res;
      } catch (error) {
        throw error;
      }
    }
    if (mission.class_name === MissionClassName.LinkoutEvent) {
      try {
        const res = await container
          .get(MissionApi)
          .triggerLinkout(mission.conditions.url, isClaimable);

        if (res.success && isClaimable) {
          AnalyticsInstance.questRewardClaimed({ reward_ids: rewardIds });
          useNotificationStore().fetchNotifications();
        }

        if (res.success && !isClaimable) {
          missionStore.setClaimable(mission.id);
        }

        return res;
      } catch (error) {
        throw error;
      }
    }

    if (mission.class_name === MissionClassName.DailyLogin && isClaimable) {
      try {
        const res = await container
          .get(MissionApi)
          .claimDailyLoginMission(mission.id);

        if (res.success && isClaimable) {
          AnalyticsInstance.questRewardClaimed({ reward_ids: rewardIds });
          useNotificationStore().fetchNotifications();
        }

        if (res.success && !isClaimable) {
          missionStore.setClaimable(mission.id);
        }

        return res;
      } catch (error) {
        throw error;
      }
    }

    if (mission.class_name === MissionClassName.ClaimCode) {
      try {
        const res = await container
          .get(MissionApi)
          .claimUgc(mission.id, undefined, true);

        if (res.success && isClaimable) {
          AnalyticsInstance.questRewardClaimed({ reward_ids: rewardIds });
          await useNotificationStore().fetchNotifications();
        }

        if (res.success && !isClaimable) {
          missionStore.setClaimable(mission.id);
        }

        return res;
      } catch (error) {
        throw error;
      }
    }

    if (mission.class_name === MissionClassName.TwitterEvent) {
      try {
        const res = await container
          .get(MissionApi)
          .claimTwitterMission(mission.id, isClaimable);

        if (res.success && isClaimable) {
          AnalyticsInstance.questRewardClaimed({ reward_ids: rewardIds });
          await useNotificationStore().fetchNotifications();
        }

        if (res.success && !isClaimable) {
          missionStore.setClaimable(mission.id);
        }

        return res;
      } catch (error) {
        throw error;
      }
    }
  }

  return { startMission, completeMission };
};
