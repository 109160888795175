<script lang="ts">
export enum ButtonSize {
  sm = "sm",
  md = "md",
  lg = "lg",
  full = "full",
  fit = "fit",
}
</script>

<script setup lang="ts">
export interface IFlatButtonProps {
  kind?: "hollow" | "danger" | "play" | "default" | "claim";
  icon?: string;
  trailingIcon?: string;
  size?: ButtonSize;
  type?: "button" | "submit" | "reset";
  borderless?: boolean;
  disabled?: boolean;
  fontBold?: "semi-bold" | "normal";
}

withDefaults(defineProps<IFlatButtonProps>(), {
  size: ButtonSize.lg,
  type: "button",
  fontBold: "semi-bold",
  kind: "default",
});

const buttonRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonRef,
});
</script>

<template>
  <button
    ref="buttonRef"
    :type="type"
    class="flex h-[58rem] shrink-0 appearance-none items-center justify-center rounded-lg px-3 text-xl shadow-home gap-2"
    :class="{
      'bg-white text-black': kind === 'hollow',
      'bg-black text-white': kind === 'default',
      'bg-[#FF6969] text-white': kind === 'danger',
      'bg-jadeMountain text-white': kind === 'play',
      'bg-[#0038FF] text-white': kind === 'claim',
      'border-[1rem] border-black': !borderless,
      'w-[160rem]': size === ButtonSize.sm,
      'w-[236rem]': size === ButtonSize.md,
      'w-[325rem]': size === ButtonSize.lg,
      'w-full': size === ButtonSize.full,
      'w-fit': size === ButtonSize.fit,
      '!bg-[#8a8a8a]': disabled,
      'cursor-not-allowed': disabled,
      'font-semibold': fontBold === 'semi-bold',
      'font-normal': fontBold === 'normal',
    }"
    :disabled="disabled"
  >
    <img v-if="icon" :src="icon" class="max-h-[22rem] pr-3" />
    <slot />
    <img v-if="trailingIcon" :src="trailingIcon" class="pl-3 h-[40rem]" />
  </button>
</template>
