<script setup lang="ts">
import { ButtonSize } from "user/components/FlatButton.vue";
import signupTwitter from "user/assets/signup-twitter.png";

defineEmits<{
  confirm: [];
}>();

const open = defineModel<boolean>("open", { required: true });
</script>

<template>
  <Modal v-model:open="open" class="h-auto">
    <div
      class="flex flex-col items-center px-[47rem] py-[50rem] text-center z-50"
    >
      <div class="pb-4 text-[30rem] font-semibold">OH WAIT!</div>
      <div class="text-[20rem] font-medium">
        Looks like you’re not signed in to X. Connect now to Start Quest
      </div>
      <img :src="signupTwitter" class="my-[35rem] h-[80rem] w-[200rem]" />
      <ButtonWithSound :size="ButtonSize.md" @click="$emit('confirm')">
        Take me there
      </ButtonWithSound>
    </div>
  </Modal>
</template>
