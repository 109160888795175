import "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";

const HomeView = () => import("./pages/home.vue");
const Index = () => import("./pages/index.vue");

const AllMarket = () => import("./pages/market/allMarket.vue");
const MarketUgc = () => import("./pages/market/ugc.vue");
const MarketGems = () => import("./pages/market/gems.vue");
const MarketOthers = () => import("./pages/market/others.vue");

const BackpackOther = () => import("./pages/backpack/other.vue");
const UgcBackpack = () => import("./pages/backpack/ugcBackpack.vue");
const Profile = () => import("./pages/profile/index.vue");
const Login = () => import("./pages/login.vue");
const Scan = () => import("./pages/scan.vue");
const Spin = () => import("./pages/stw/SpinTheWheel.vue");

const Settings = () => import("./pages/settings/index.vue");
const Feedback = () => import("./pages/settings/feedback.vue");
const Privacy = () => import("./pages/settings/privacy.vue");
const Terms = () => import("./pages/settings/terms.vue");

const Backpack = () => import("./layouts/backpack.vue");
const Market = () => import("./layouts/market.vue");
const Missions = () => import("./layouts/missions.vue");

const BoothQr = () => import("./pages/booth/booth-qr.vue");
const Tracking = () => import("./pages/tracking/Tracking.vue");
const Referrals = () => import("./pages/referrals/Referrals.vue");
const BadgeBackpack = () => import("./pages/backpack/badgeBackpack.vue");
const Brand = () => import("./pages/brands/brand.vue");
const BuySpin = () => import("./pages/stw/BuySpin.vue");
const SpinTheWheel = () => import("./pages/stw/SpinTheWheel.vue");

import { useSessionStore } from "user/stores/session";
import { useBoothStore } from "user/stores/booth";

export const MISSIONS_PAGE_NAME = "quests";
export const MISSION_QUERY_PARAM = "questId";
export const MISSION_FILTER_QUERY_PARAM = "type";
export const enum MISSION_STATUS {
  AVAILABLE = "available",
  IN_PROGRESS = "in-progress",
  COMPLETED = "completed",
}
export const getMissionPagePath = (type: string, status: MISSION_STATUS) =>
  `/${MISSIONS_PAGE_NAME}/${type}/${status}`;

const routes = [
  { path: "/", component: Index, name: "index" },
  { path: "/login", name: "login", component: Login },
  { path: "/home", component: HomeView, name: "home" },
  {
    path: "/market",
    name: "market",
    component: Market,
    children: [
      { path: "/market/all", name: "market-all", component: AllMarket },
      { path: "/market/ugc", name: "market-ugc", component: MarketUgc },
      { path: "/market/gems", name: "market-gems", component: MarketGems },
      {
        path: "/market/others",
        name: "market-others",
        component: MarketOthers,
      },
    ],
  },
  {
    path: `/${MISSIONS_PAGE_NAME}/:type/:status`,
    component: Missions,
    name: MISSIONS_PAGE_NAME,
  },
  { path: "/brand/:id", component: Brand, name: "brand" },
  {
    path: "/backpack",
    component: Backpack,
    name: "backpack",
    children: [
      { path: "/backpack/ugc", name: "backpack-ugc", component: UgcBackpack },
      {
        path: "/backpack/badge",
        name: "backpack-badge",
        component: BadgeBackpack,
      },
      {
        path: "/backpack/other",
        name: "backpack-other",
        component: BackpackOther,
      },
    ],
  },
  {
    path: "/booth/:token",
    component: BoothQr,
    name: "booth-reward",
  },
  { path: "/profile", component: Profile, name: "profile" },
  { path: "/scan", component: Scan },
  { path: "/spin-the-wheel", component: SpinTheWheel, name: "spin-the-wheel" },
  { path: "/spin-the-wheel/buy", component: BuySpin, name: "buy-spin" },
  { path: "/spin-the-wheel/:oboarding", component: Spin },
  { path: "/settings", component: Settings, name: "settings" },
  { path: "/settings/feedback", component: Feedback, name: "feedback" },
  {
    path: "/profile/referrals",
    component: Referrals,
    name: "profile-referrals",
  },
  { path: "/settings/privacy", component: Privacy, name: "privacy" },
  { path: "/settings/terms", component: Terms, name: "terms" },
  {
    path: "/profile/referrals/tracking",
    component: Tracking,
    name: "profile-referrals-tracking",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  const session = useSessionStore();
  const booths = useBoothStore();
  let urlParams = new URLSearchParams(window.location.search);
  const booth_token = urlParams.get("booth_token");

  if (
    (session.currentUser == null || session.isAnonymous) &&
    to.name !== from.name
  ) {
    await session.tryFetchCurrentUser();
  }
  if (booth_token != null) {
    booths.setPendingBooth(booth_token);
  }
  if (session.isAnonymous && to.path == "/login") {
    return;
  }
  if ((session.currentUser == null || session.isAnonymous) && to.path === "/") {
    return { path: "/login" };
  }
  if (session.currentUser != null && to.path === "/") {
    return { path: "home" };
  }
});

export default router;
