import { Howl, HowlOptions } from "howler";

const DEFAULT_VOLUME = 0.3;

class AudioPlayer {
  private sound: Howl | null = null;
  private isFaded: boolean = false;
  private initialVolume: number = DEFAULT_VOLUME;
  private isMuted: boolean = true;

  constructor(options: HowlOptions) {
    this.initialVolume = options.volume ?? DEFAULT_VOLUME;

    this.sound = new Howl({
      volume: options.volume ?? DEFAULT_VOLUME,
      mute: this.isMuted,
      ...options,
    });
  }

  volume(vol: number) {
    this.sound?.volume(vol);
  }

  play() {
    if (this.isFaded && this.sound) {
      this.sound.volume(this.initialVolume);
    }

    this.sound?.mute(false);
    this.sound?.play();
  }

  stop() {
    this.sound?.stop();
  }

  mute() {
    this.sound?.mute();
  }

  fade(to: number = 0, duration: number = 1000) {
    this.sound?.fade(this.sound.volume(), to, duration);
    this.isFaded = true;

    setTimeout(() => {
      this.stop();
    }, duration);
  }
}

export default AudioPlayer;
