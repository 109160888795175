import { defineStore } from "pinia";
import { ref } from "vue";
import { MissionApi } from "user/api/mission";
import container from "shared/inversify.config";
import {
  MissionFilter,
  MissionCategory,
  MissionClassName,
} from "user/models/mission";
import { Mission } from "user/models/mission";

export const useMissionStore = defineStore("mission", () => {
  const categories = ref<MissionCategory[]>([]);
  const available = ref<Mission[]>([]);
  const active = ref<Mission[]>([]);
  const complete = ref<Mission[]>([]);
  const referral = ref<Mission[]>([]);

  const completedMissionsMap = ref<Record<number, Mission>>({});
  const availableMissionsMap = ref<Record<number, Mission>>({});
  const activeMissionsMap = ref<Record<number, Mission>>({});

  const selectedMission = ref<Mission>();

  function $reset() {
    available.value = [];
    active.value = [];
    complete.value = [];
    referral.value = [];
  }

  function getMissionById(id: number, filter?: MissionFilter) {
    switch (filter) {
      case MissionFilter.Completed:
        return completedMissionsMap.value[id];
      case MissionFilter.Active:
        return activeMissionsMap.value[id];
      case MissionFilter.Available:
        return availableMissionsMap.value[id];
    }
    if (availableMissionsMap.value[id] !== undefined) {
      return availableMissionsMap.value[id];
    }
    if (activeMissionsMap.value[id] !== undefined) {
      return activeMissionsMap.value[id];
    }
    if (completedMissionsMap.value[id] !== undefined) {
      return completedMissionsMap.value[id];
    }
  }

  function getMissionByIdentifier(id: string, filter?: MissionFilter) {
    switch (filter) {
      case MissionFilter.Completed:
        return complete.value.find((el) => el.identifier === id);
      case MissionFilter.Active:
        return active.value.find((el) => el.identifier === id);
      case MissionFilter.Available:
        return available.value.find((el) => el.identifier === id);
    }
    let m = available.value.find((el) => el.identifier === id);
    if (m !== undefined) {
      return m;
    }
    m = active.value.find((el) => el.identifier === id);
    if (m !== undefined) {
      return m;
    }
    m = complete.value.find((el) => el.identifier === id);
    if (m !== undefined) {
      return m;
    }
    return m;
  }

  async function getMission(id: number): Promise<Mission> {
    const mission = await container.get(MissionApi).getMissionById(id);

    return mission;
  }

  function setClaimable(id: number) {
    const m = active.value.find((m) => m.id === id);
    if (!m) {
      return;
    }

    m.isClaimable = true;
  }

  function getMissions(filter: MissionFilter): Mission[] | undefined {
    switch (filter) {
      case MissionFilter.Completed:
        return complete.value;
      case MissionFilter.Active:
        return active.value;
      default:
      case MissionFilter.Available:
        return available.value;
    }
  }

  async function fetchMissions() {
    const activeMap: Record<number, Mission> = {};
    const availableMap: Record<number, Mission> = {};
    const completedMap: Record<number, Mission> = {};
    const missions = await container.get(MissionApi).getMissions();
    const refMissions: Mission[] = [];

    active.value = missions.active.map((el) => {
      const m = { ...el, isActive: true };

      // if (
      //   m.class_name === MissionClassName.ReferralEvent ||
      //   m.class_name === MissionClassName.RobloxUpdate
      // ) {
      m.isClaimable = false;
      // }

      activeMap[m.id] = m;
      return m;
    });
    available.value = missions.available.map((el) => {
      const m = {
        ...el,
        isAvailable: true,
      };

      availableMap[m.id] = m;
      return m;
    });
    missions.claimable.forEach((m) => {
      active.value.push({
        ...m,
        isClaimable: true,
        isActive: true,
      });
    });
    complete.value = missions.completed.map((el) => {
      const m = {
        ...el,
        isCompleted: true,
      };
      completedMap[m.id] = m;

      return m;
    });

    referral.value = refMissions;
    activeMissionsMap.value = activeMap;
    availableMissionsMap.value = availableMap;
    completedMissionsMap.value = completedMap;
  }

  function setSelectedMission(mission: Mission) {
    selectedMission.value = mission;
  }

  function resetSelectedMission() {
    selectedMission.value = undefined;
  }

  function isStandardMission(mission: Mission) {
    return (
      mission.class_name === MissionClassName.Standard ||
      mission.class_name === null
    );
  }

  function getMissionFilter(mission: Mission) {
    if (mission.isActive) {
      return MissionFilter.Active;
    }

    if (mission.isCompleted) {
      return MissionFilter.Completed;
    }

    if (mission.isAvailable) {
      return MissionFilter.Available;
    }
    return undefined;
  }

  return {
    $reset,
    getMission,
    getMissions,
    getMissionById,
    getMissionByIdentifier,
    fetchMissions,
    setSelectedMission,
    resetSelectedMission,
    isStandardMission,
    setClaimable,
    getMissionFilter,
    categories: shallowReadonly(categories),
    getAvailable: shallowReadonly(available),
    getActive: shallowReadonly(active),
    getCompleted: shallowReadonly(complete),
    selectedMission: shallowReadonly(selectedMission),
    referral: shallowReadonly(referral),
    activeMissionsMap: shallowReadonly(activeMissionsMap),
    availableMissionsMap: shallowReadonly(availableMissionsMap),
    completedMissionsMap: shallowReadonly(completedMissionsMap),
  };
});
