<script setup lang="ts">
import { useSettingStore } from "user/stores/settings";
import CloseIcon from "user/assets/close.svg";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";

const settingStore = useSettingStore();

const showAd = defineModel<boolean>("showAd", { required: true });

function handleClick() {
  window.open(settingStore.settings.ad_button_link, "_blank");
  showAd.value = false;
}

function handleClose() {
  showAd.value = false;
}
</script>

<template>
  <TransitionRoot :show="showAd">
    <TransitionChild
      as="template"
      enter="duration-300 ease-out transition-[opacity,transform]"
      enter-from="opacity-0 translate-y-[100rem]"
      enter-to="opacity-100 translate-y-0"
      leave="duration-200 ease-in transition-[opacity,transform]"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div
        class="fixed z-[100] overflow-hidden top-0 left-0 h-[calc(100%-70rem)] md:h-full w-full bg-black/60 flex"
      >
        <div class="w-[260rem] hidden md:block" />
        <div
          class="grow h-full flex items-center justify-center px-[60rem] mx-auto"
        >
          <div class="relative rounded-ml">
            <div
              class="absolute right-0 top-0 -translate-y-full translate-x-full p-2 bg-white rounded-full cursor-pointer"
              @click="handleClose"
            >
              <img :src="CloseIcon" alt="Close" />
            </div>
            <img
              :src="settingStore.settings.ad_poster_image"
              alt="Post"
              class="rounded-ml min-w-[260rem] min-h-[260rem] max-h-[50vh]"
              :class="{
                'bg-black min-w-[260rem] min-h-[260rem]':
                  !settingStore.settings.ad_poster_image,
              }"
            />
            <AdButton
              @click="handleClick"
              class="!absolute -bottom-6 left-1/2 -translate-x-1/2"
              :color="settingStore.settings.ad_button_color"
            >
              {{
                settingStore.settings.ad_button_text
                  ? settingStore.settings.ad_button_text
                  : "Learn More"
              }}
            </AdButton>
          </div>
        </div>
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>
