import { injectable } from "inversify";
import { event, config } from "vue-gtag";

// {SUBJECT}_{VERB} or {SUBJECT}_{STATE}
export enum GtagEvents {
  // ENGAGEMENT
  QUEST_STARTED = "quest_started",
  QUEST_COMPLETED = "quest_complete",
  QUEST_REWARD_CLAIMED = "quest_reward_claimed",
  QUEST_ACTIVATION_TRY = "quest_activation_try",
  QUEST_ACTIVATION_FAIL = "quest_activation_fail",
  QUEST_DESCRIPTION_VIEWED = "quest_description_viewed",
  QUEST_TRIGGER_LINKOUT = "quest_trigger_linkout",

  // Spin The Wheel (STW)
  WHEEL_SPIN = "wheel_spin",

  // Market Purchases
  REWARD_PURCHASE = "reward_purchase",
  // TODO: clarify & delete if redundant
  // GEM_PURCHASE = "gem_purchase",
  // UGC_PURCHASE = "ugc_purchase",
  // SPIN_PURCHASE = "spin_purchase",
  // LOOTBOX_PURCHASE = "lootbox_purchase",

  // STARs & GEMs Conversions
  STARS_SPENT_IN_PURCHASE = "stars_spent_in_purchase",
  GEMS_SPENT_IN_PURCHASE = "gems_spent_in_purchase",
  // TODO: Is it for: STARs & GEMs Conversions, STARs Earned data?
  QUEST_STARS_EARNED = "quest_stars_earned",

  // REFERRALS
  REFERRAL_CODE_APPLIED = "referral_code_applied",

  LOOTBOX_OPEN = "lootbox_open",
  MERCH_CLAIMED = "merch_claimed",
  FEEDBACK_SEND = "feedback_send",
  POST_ON_X = "post_on_x",

  // Home page sections clicks
  FEATURED_BANNER_SECTION_CLICKED = "featured_banner_section_clicked",
  TRENDING_SECTION_CLICKED = "trending_section_clicked",
  CUSTOM_CTA_SECTION_CLICKED = "custom_cta_section_clicked",
  REFERRAL_SECTION_CLICKED = "referral_section_clicked",
}

interface EventParams {
  [key: string]: any;
}

@injectable()
export class AnalyticsApi {
  public userId: number | null = null;
  private enabled: boolean = false;

  constructor(GA_KEY: string = "") {
    this.enabled = GA_KEY ? true : false;
    // this.enabled = false; // turnoff analytic until receive events list
  }

  private trackEvent(eventName: GtagEvents, params?: EventParams) {
    if (!this.enabled) {
      return;
    }

    event(eventName, { ...params, userId: this.userId });
  }

  setUserIdConfig(userId: number) {
    config({ user_id: userId });
    // TODO: redundant?
    this.userId = userId;
  }

  resetUserIdConfig() {
    config({ user_id: null });
    this.userId = null;
  }

  questStarted(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_STARTED, params);
  }

  questCompleted(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_COMPLETED, params);
  }

  questRewardClaimed(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_REWARD_CLAIMED, params);
  }

  // TODO:
  questStarsEarned(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_STARS_EARNED, params);
  }

  questActivationTry(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_ACTIVATION_TRY, params);
  }

  questActivationFail(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_ACTIVATION_FAIL, params);
  }

  questDescriptionViewed(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_DESCRIPTION_VIEWED, params);
  }

  wheelSpin(params?: EventParams) {
    this.trackEvent(GtagEvents.WHEEL_SPIN, params);
  }

  rewardPurchase(params?: EventParams) {
    this.trackEvent(GtagEvents.REWARD_PURCHASE, params);
  }

  starsSpentInPurchase(params?: EventParams) {
    this.trackEvent(GtagEvents.STARS_SPENT_IN_PURCHASE, params);
  }

  gemsSpentInPurchase(params?: EventParams) {
    this.trackEvent(GtagEvents.GEMS_SPENT_IN_PURCHASE, params);
  }

  postOnX(params?: EventParams) {
    this.trackEvent(GtagEvents.POST_ON_X, params);
  }

  questTriggerLinkout(params?: EventParams) {
    this.trackEvent(GtagEvents.QUEST_TRIGGER_LINKOUT, params);
  }

  referralCodeApplied(params?: EventParams) {
    this.trackEvent(GtagEvents.REFERRAL_CODE_APPLIED, params);
  }

  merchClaimed(params?: EventParams) {
    this.trackEvent(GtagEvents.MERCH_CLAIMED, params);
  }

  lootboxOpen(params?: EventParams) {
    this.trackEvent(GtagEvents.LOOTBOX_OPEN, params);
  }

  feedbackSend(params?: EventParams) {
    this.trackEvent(GtagEvents.FEEDBACK_SEND, params);
  }

  featuredBannerSectionClicked(params?: EventParams) {
    this.trackEvent(GtagEvents.FEATURED_BANNER_SECTION_CLICKED, params);
  }

  trendingSectionClicked(params?: EventParams) {
    this.trackEvent(GtagEvents.TRENDING_SECTION_CLICKED, params);
  }

  customCTASectionClicked(params?: EventParams) {
    this.trackEvent(GtagEvents.CUSTOM_CTA_SECTION_CLICKED, params);
  }

  referralSectionClicked(params?: EventParams) {
    this.trackEvent(GtagEvents.REFERRAL_SECTION_CLICKED, params);
  }
}

const AnalyticsInstance = new AnalyticsApi(window.GA_ID);

export default AnalyticsInstance;
