<script setup lang="ts">
const props = withDefaults(
  defineProps<{ constrainX?: number; constrainY?: number }>(),
  { constrainX: 130, constrainY: 50 },
);

const cardRef = ref<HTMLDivElement | null>(null);

function transforms(x: number, y: number): string {
  if (!cardRef.value) return "";
  const box = cardRef.value.getBoundingClientRect();
  const calcX = -(y - box.y - box.height / 2) / props.constrainX;
  const calcY = (x - box.x - box.width / 2) / props.constrainY;

  return `perspective(210rem)rotateX(${calcX}deg)rotateY(${calcY}deg)`;
}

function animate(e: MouseEvent) {
  if (!cardRef.value) return;

  nextTick(() => {
    if (!cardRef.value) return;
    cardRef.value.style.transform = transforms(e.clientX, e.clientY);
  });
}

function onMouseEnter() {
  if (!cardRef.value) return;
  cardRef.value.classList.add("duration-150");
  cardRef.value.classList.remove("duration-300");

  setTimeout(() => {
    if (!cardRef.value) return;
    cardRef.value.classList.remove("duration-150");
  }, 300);
}

function mouseLeave() {
  nextTick(() => {
    if (!cardRef.value) return;

    cardRef.value.style.transform = "rotateX(0deg)rotateY(0deg)";
    cardRef.value.classList.add("duration-300");
  });
}
</script>

<template>
  <div
    class="card-transform"
    ref="cardRef"
    @mousemove="animate"
    @mouseenter="onMouseEnter"
    @mouseout="mouseLeave"
    @mouseleave="mouseLeave"
  >
    <slot />
  </div>
</template>

<style>
.card-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
